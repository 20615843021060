@keyframes animationBackground {
  0% {
    background-position: 0px;
  }
  100% {
    background-position: 1000px;
  }
}

@keyframes animationBalloons {
  0% {
    border-radius: 67% 33% 28% 72% / 59% 52% 48% 41%;
  }
  20% {
    border-radius: 72% 28% 33% 67% / 41% 48% 52% 59%;
  }
  50% {
    border-radius: 50%;
  }
  80% {
    border-radius: 72% 28% 33% 67% / 41% 48% 52% 59%;
  }
  100% {
    border-radius: 67% 33% 28% 72% / 59% 52% 48% 41%;
  }
}

@keyframes animateArrow {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
