@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('../fonts/Poppins-Regular.ttf') format('woff');
}
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url('../fonts/Poppins-Bold.ttf') format('woff');
  font-weight: bold;
}
@font-face {
  font-family: 'Grobold';
  src: local('Grobold'), url('../fonts/Grobold.ttf') format('woff');
}

* {
  font-family: 'Poppins' !important;
}

html {
  scroll-behavior: smooth;
  background-color: #ffdde580;
}

.btn-swap {
  font-size: 20px;
  color: #fff;
  text-align: center;
  padding: 4px;
  border-radius: 20px;
  background: #ef8005;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.box-error {
  display: block;
  color: red;
}

.flex {
  display: flex;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}

/* Modal */
.ant-modal-wrap {
  width: calc(100% - 8px);
}

@media only screen and (max-width: 768px) {
  .hidden-mobile {
    display: none !important;
  }
}

.ant-select-dropdown {
  background-color: #fff !important;
}
.ant-select-item-option-content {
  color: #a1a1aa !important;
}
.ant-select-item.ant-select-item-option-selected,
.ant-select-item-option-active {
  background-color: #fff1f5 !important;
}

.ant-select-disabled .ant-select-selector {
  background: #27272a !important;
  color: #a1a1aa !important;
}

.flex-1 {
  flex: 1;
}

.modal-success .ant-modal-body {
  background: url('../images/background/success-popup-bg.jpg') 100% 100% no-repeat !important;
  border-radius: 18px !important;
}

.div[role='presentation'] {
  background: transparent;
}

.enter-done {
  position: relative;
  z-index: 1100 !important;
}

img {
  max-width: 100%;
}

h4 {
  font-size: 2rem !important;
  line-height: 2rem !important;
  font-weight: 600 !important;
}

@media (min-width: 991px) {
  h4 {
    font-size: 2.25rem !important;
    line-height: 2.25rem !important;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #eef2fc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(254,110,162) !important;
  border-radius: 18px;
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-btn {
  font-weight: 600 !important;
  border-radius: 0.75rem !important;
  border-width: 2px !important;
  min-height: 40px !important;
}

.ant-btn-primary {
  background: #0063f7 !important;
}

.ant-btn:disabled {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}

.ant-btn-background-ghost {
  border-color: #0063f7 !important;
}

.ant-btn-background-ghost:hover {
  background: #0063f7 !important;
}

.ant-btn-dashed {
  background-color: transparent !;
}

.container {
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
}

button {
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
}

button:disabled {
  cursor: no-drop !important;
}

.text-secondary {
  color: #d3d1ff !important;
}

.ant-table {
  background: transparent !important;
}

.ant-table-thead > tr > th {
  background: #ff6da2 !important;
  color: #fff !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: rgba(255, 255, 255, 0.05) !important;
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent !important;
}

.ant-input-number {
  background: #fff1f5 !important;
  border: none !important;
}

.ant-input-number .ant-input-number-handler-wrap {
  display: none !important;
}

.ant-input-number input {
  min-height: 42px;
  color: #ff82af;
}

.ant-input {
  border: none !important;
  min-height: 42px;
}

.ant-input:not(:disabled) {
  background: #fff1f5 !important;
  color: #ff82af;
}

.ant-input-group-addon {
  background: #fff1f5 !important;
  border: none !important;
  min-height: 42px;
  min-width: 42px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #fff1f5 !important;
}
